import { STermCodes } from 'core/constants/app-constants';
import { parseAsuTerm } from 'core/utils/date-utils';
import {
  attachFulfilledRequirementNames,
  filterRemainedRequirements,
  makeCardId,
  processClasses,
  resetCounterClassUId,
} from './process-courses';
import {
  createSessionSchema,
  defaultSessionMap,
  getAllSessionClasses,
  processSessionDates,
  processSessionRestrictions,
  sessionKeyMap,
} from './process-term-sessions';
import { processTransferCredits } from './process-transfer-credits';
import { processSummaryData } from './process.summary';

function sumPlanTermCredits(
  classes: {
    status?: API.PlanData.ClassStatus;
    creditHours: number;
    creditHoursRemaining?: number;
    tag?: string;
  }[],
) {
  let inProgress = 0,
    registered = 0,
    planned = 0,
    remaining = 0,
    completed = 0;

  for (const c of classes) {
    if (c.tag) continue;

    if (c.status === 'in-progress') {
      inProgress += c.creditHours;
    } else if (c.status === 'not-started') {
      registered += c.creditHours;
    } else if (c.status === 'completed') {
      completed += c.creditHours;
    } else if (c.creditHoursRemaining) {
      remaining += c.creditHoursRemaining;
    } else {
      planned += c.creditHours;
    }
  }

  const totalByStatus = {
    inProgress,
    registered,
    planned,
    remaining,
    completed,
  };

  const total = Object.values(totalByStatus).reduce(
    (acc, current) => acc + current,
  );

  return {
    total,
    ...totalByStatus,
  };
}

/**
 * @deprecated since https://asudev.jira.com/browse/CMIDP-1299
 * TODO: This was removed from the UI, I leave it for testing
 * and eventually it will be removed if not used it anymore
 */
function sumTermCumulativeCredits(
  studentProgress: API.PlanData.StudentProgress[],
) {
  studentProgress.forEach((term, index, array) => {
    const current = term.completedCreditHour || 0;
    const prev =
      index > 0 ? array[index - 1]._uiMetaData?.cumulativeCreditHours || 0 : 0;

    term._uiMetaData = {
      cumulativeCreditHours: prev + current,
    };
  });

  const lastIndex = studentProgress.length - 1;
  const lastValue =
    studentProgress[lastIndex]?._uiMetaData?.cumulativeCreditHours || 0;

  return lastValue;
}

/**
 *  Merge completed class which Term is still in progress
 */
function mergeCompletedClassToCurrentTerm(
  inputPlan: API.PlanData.Plan,
): API.PlanData.Plan {
  const plan = { ...inputPlan };
  // =====================================================
  // list all term codes
  // =====================================================
  const inprogressTermCodes = plan.degreePlan.map(
    (plannedTerm) => plannedTerm.term,
  );
  const completedTermCodes = plan.studentProgress.map(
    (completedItem) => completedItem.term,
  );
  const overlappedTermCodes = completedTermCodes.filter((termCode) =>
    inprogressTermCodes.includes(termCode),
  );
  // =====================================================
  // process completed terms
  // =====================================================
  const overlappedCompletedTerms: API.PlanData.StudentProgress[] = [];
  const completedTerms: API.PlanData.StudentProgress[] = [];
  plan.studentProgress.forEach((term) => {
    // Overlap term; to merge with current terms
    if (overlappedTermCodes.includes(term.term)) {
      overlappedCompletedTerms.push({ ...term });
    }
    // No overlap; term can be display as past term
    else {
      completedTerms.push({ ...term });
    }
  });

  const studentProgress = completedTerms;
  // =====================================================
  // process Inprogress/Planned terms
  // =====================================================
  const degreePlan = plan.degreePlan.map((term) => {
    const overlap = overlappedCompletedTerms.find(
      (overlappedTerm) => overlappedTerm.term === term.term,
    );

    if (overlap) {
      overlap.classes.forEach((completedClass) => {
        const session = term.sessions.find(
          ({ sessionName }) => sessionName === completedClass.sessionCode,
        );

        if (session) {
          session.classes = [...(session.classes || []), completedClass];
        } else {
          term.sessions.push({
            sessionName: completedClass.sessionCode,
            classes: [completedClass],
            requiredClasses: [],
            selectedClasses: [],
          });
        }
      });
    }

    return {
      ...term,
    };
  });
  // =====================================================
  return {
    ...plan,
    degreePlan,
    studentProgress,
  };
}

function preProcessPlanForUI({
  rawPlan,
  termMap = null,
  currentTerm = null,
}: {
  rawPlan: API.PlanData.Plan | null;
  termMap?: DPL_API.TermData.TermMap | null;
  currentTerm?: Readonly<DPL_API.TermData.Term> | null;
}) {
  if (!rawPlan || rawPlan.buildStatus !== 'COMPLETE') return rawPlan;

  // TODO: waiting for https://asudev.jira.com/browse/CMIDP-888
  // TODO: if (rawPlan.isActivePlan) {
  // TODO:   rawPlan.planName = ACTIVE_PLAN_DEFAULT_NAME;
  // TODO: }

  resetCounterClassUId();
  // =====================================================
  // Adjust Plan terms
  // =====================================================
  const mergedTermsPlan = mergeCompletedClassToCurrentTerm(rawPlan);
  const plan = adjustPlanToCurrentTerm(currentTerm, mergedTermsPlan);
  // Set ActivePlan flag
  if (plan.isActivePlan === undefined) {
    plan.isActivePlan = plan.uuid.toLowerCase() === 'active';
  }
  // =====================================================
  // Term counter
  // =====================================================
  const termStartSequenceIndex = plan.studentProgress.length + 1;
  // =====================================================
  //  Plan metadata
  // =====================================================
  const { studentProgress } = plan;
  // =====================================================
  //  Plan metadata => validationErrors
  // =====================================================
  let failureWarnings: API.PlanData.FailureWarning[] = [];

  if (plan.validationErrors) {
    const courseValidations = Array.isArray(plan.validationErrors)
      ? plan.validationErrors
      : [plan.validationErrors];

    failureWarnings = courseValidations
      .sort((warning) => warning.termCode && warning.sessionCode)
      .filter((warning) => typeof warning === 'object')
      .map<API.PlanData.FailureWarning>((warning) => ({
        reason: warning.reason,
        termCode: warning.termCode,
        sessionCode: warning.sessionCode,
        subject: warning.subject!,
        catalogNumber: warning.catalogNumber!,
        validationError: warning.validationError || [],
      }));
  }
  // =====================================================
  //  Plan metadata => Session Requirements
  // =====================================================
  const sessionRequirements = plan.degreePlan.flatMap((term) =>
    term.sessions.flatMap((session) => session.requiredClasses || []),
  );

  // =====================================================
  plan._uiMetaData = {
    validator: { failureWarnings },
    sessionRequirements,
    termGroup: [],
  };
  // =====================================================
  // Check if the plan include the Summer Term
  // =====================================================
  const totalTerms = plan.degreePlan.length;
  const nextValidIndex = totalTerms > 1 ? 1 : 0;
  const { term } = plan.degreePlan?.[nextValidIndex];

  const semester = term[term.length - 1];
  const maxTermIndexToDisplaySessionDates =
    semester === STermCodes.summer ? 2 : 1;
  // =====================≈================================
  //  Student Progress & transfer Credits
  // =====================================================
  plan.transferCredits = processTransferCredits(plan.transferCredits);
  // =====================≈================================
  const planHistoryTerms: {
    list: API.PlanData.BaseTerm[];
    creditSource: API.PlanData.CreditSource;
  }[] = [
    {
      list: plan.transferCredits.courseCredits,
      creditSource: 'course-credit',
    },
    {
      list: plan.transferCredits.testCredits,
      creditSource: 'test-credit',
    },
    {
      list: plan.studentProgress,
      creditSource: 'class-credit',
    },
  ];

  planHistoryTerms.forEach(({ list, creditSource }) => {
    list.forEach((term, termIndex) => {
      // ui metadata
      const termUId = `term-${termIndex}`;
      const sessionUId = `${termUId}-session-${0}`;
      // parse termName like "1995 Fall" or "" to be like "Fall 1995"
      if (Number.isInteger(Number.parseInt(term.termName)) || !term.termName) {
        term.termName = parseAsuTerm(term.term).sessionYear;
      }

      term.classes = processClasses({
        isTermInprogress: false,
        creditSource,
        classType: 'enrolled',
        termUId,
        sessionUId,
        sessionPending: false,
        classes: term.classes,
        defaultClassStatus: 'completed',
      });
    });
  });
  // =====================================================
  // Sum Cumulative Credit hours
  // =====================================================
  sumTermCumulativeCredits(studentProgress);

  // =====================================================
  const finalTermIndex = plan.degreePlan.length - 1;
  plan.degreePlan.forEach((term, termIndex, array) => {
    const termCode = term.term;
    term.termName = parseAsuTerm(termCode).sessionYear;
    // =====================================================
    // ui metadata
    // =====================================================
    const termUId = `term-${termIndex}`;
    const termSequenceIndex = termStartSequenceIndex + termIndex;
    const termTitle = `Term ${termSequenceIndex}`;

    term._uid = termUId;
    term._uiMetaData = {
      termTitle,
      index: termStartSequenceIndex + termIndex,
      totalCreditHour: 0,
      inProgressCreditHour: 0,
      registeredCreditHour: 0,
      plannedCreditHour: 0,
      remainingCreditHour: 0,
      cumulativeCreditHours: 0,
      completedCreditHour: 0,
      isCurrent: term.term === currentTerm?.strm,
      isFinal: termIndex === finalTermIndex,
    };
    // =====================================================
    // sessions
    // =====================================================
    const { sessionList, sessionMap } = createSessionSchema(term, termMap);
    term.sessions = sessionList;
    term.sessions.forEach((session, sessionIndex) => {
      session.requiredClasses = filterRemainedRequirements(
        session.requiredClasses,
      );
      const classElements = getAllSessionClasses(session);

      // =====================================================
      // Sum Term Credit hours
      // =====================================================
      const classCredits = sumPlanTermCredits(classElements);
      term._uiMetaData!.inProgressCreditHour! += classCredits.inProgress;
      term._uiMetaData!.registeredCreditHour! += classCredits.registered;
      term._uiMetaData!.plannedCreditHour! += classCredits.planned;
      term._uiMetaData!.remainingCreditHour! += classCredits.remaining;
      term._uiMetaData!.completedCreditHour! += classCredits.completed;
      term._uiMetaData!.totalCreditHour! += classCredits.total;
      // =====================================================
      // UI Session metadata
      // =====================================================
      const sessionCode = session.sessionName as keyof typeof sessionKeyMap;
      const sessionUId = `${term._uid}-session-${sessionIndex}`;
      const displayName = sessionKeyMap[sessionCode];
      const sessionPending = !session.sessionName;

      session._uid = sessionUId;
      session._uiMetaData = {
        termUId,
        sessionUId,
        sessionCode,
        displayName,
        startDate: null,
        endDate: null,
        lastEnrollmentDate: null,
        sessionPending,
        dropDeadlineInfo: {
          lastDay: '',
          sessionDropLabel: '',
          courseDropLabel: '',
          classDropPlanned: false,
        },
        allowDropWithdrawal: true,
        allowMoveInSession: true,
      };

      const sessionData = sessionMap?.[sessionCode] || null;
      // =====================================================
      // UI Session metadata -> Current catalog year
      // =====================================================
      if (termIndex <= maxTermIndexToDisplaySessionDates) {
        processSessionDates(session, sessionData);
        processSessionRestrictions(session, sessionData);
      }
      // =====================================================

      session.classes = processClasses({
        isTermInprogress: true,
        classType: 'enrolled',
        termUId,
        sessionUId,
        sessionPending: false,
        classes: session.classes,
        creditSource: 'class-credit',
      });

      // =====================================================
      session.requiredClasses = processClasses({
        isTermInprogress: true,
        classType: 'required',
        termUId,
        sessionUId,
        sessionPending: false,
        classes: session.requiredClasses,
        creditSource: 'class-credit',
      });
      // =====================================================
      session.selectedClasses = processClasses({
        isTermInprogress: true,
        classType: 'selected',
        termUId,
        sessionUId,
        sessionPending,
        classes: session.selectedClasses,
        creditSource: 'class-credit',
      });

      attachFulfilledRequirementNames(
        session.selectedClasses,
        sessionRequirements,
      );
      // =====================================================
    });

    // =====================================================
    // Sum Cumulative Credit Hours
    // =====================================================
    const current = term._uiMetaData?.totalCreditHour || 0;
    const prev =
      termIndex > 0
        ? array[termIndex - 1]._uiMetaData?.cumulativeCreditHours || 0
        : 0;
    term._uiMetaData!.cumulativeCreditHours = prev + current;
  });

  // =====================================================
  // Process Summary data
  // =====================================================
  processSummaryData(plan);
  // =====================================================
  // Process Term data
  // =====================================================
  plan._uiMetaData.termGroup = [
    ...plan.studentProgress.map((t) => ({
      termCode: t.term,
      isCurrent: false,
    })),
    ...plan.degreePlan.map((t) => ({
      termCode: t.term,
      isCurrent: Boolean(t._uiMetaData?.isCurrent),
    })),
  ];
  // =====================================================

  return plan;
}

/**
 * Fix old plans created before the Current term switch.
 * Process the `degreePlan` term to ensure to display the current term correctly.
 */
function adjustPlanToCurrentTerm(
  currentTerm: DPL_API.TermData.Term | null,
  planData: API.PlanData.Plan,
): API.PlanData.Plan {
  if (!currentTerm) return planData;

  const sessionList = Object.keys(defaultSessionMap);

  const currentTermPlaceHolder: API.PlanData.Term = {
    term: currentTerm.strm as API.AsuSTerm,
    termName: parseAsuTerm(currentTerm.strm).sessionYear,
    sessions: sessionList.map((sessionName) => ({
      sessionName,
      classes: [],
      requiredClasses: [],
      selectedClasses: [],
    })),
  };

  let { studentProgress, degreePlan } = planData;
  const currentTermIndex = degreePlan.findIndex(
    (t) => t.term === currentTerm.strm,
  );

  // #1 case. The current term is the correct fist position
  if (currentTermIndex === 0) {
    return planData;
  }
  // #2 case. the degree plan does not contain the current-term
  else if (currentTermIndex === -1) {
    // insert current-term in the first position
    degreePlan.unshift(currentTermPlaceHolder);
  }
  // #3 case. the degree plan does "contains" the current-term but the plan was created before the current-term switch
  // TODO: to discuss if this case needs to be covered. Remove the code once decision is taken
  // Do nothing in case, some of the classes might still be in "Progress" status
  /** TODO: @deprecated code. Remove this commented code once new UI is in place*/
  // # else if (currentTermIndex > 0) {
  // #   pick ony the current-term from the old plan
  // #   degreePlan = [degreePlan[currentTermIndex]];
  // # }
  // TODO: to discuss if this case needs to be covered. Remove the code once decision is taken
  // #4 case. the degree plan "contains" the current-term, and there n term prior the current-term
  // * The expired term plan will be moved to `studentProgress`?

  return {
    ...planData,
    degreePlan,
    studentProgress,
  };
}

function sortPlanByDate(planList: API.PlanData.PlanRecord[]) {
  /** Arbitrary date to move those records without a TimeStamp down the list */
  const defaultTimeStamp = new Date(2022, 11, 1).toISOString();

  const sortedPlanList = [...planList].sort(
    (a, b) =>
      Date.parse(b.lastModified || defaultTimeStamp) -
      Date.parse(a.lastModified || defaultTimeStamp),
  );

  return sortedPlanList;
}

export {
  adjustPlanToCurrentTerm,
  preProcessPlanForUI,
  filterRemainedRequirements,
  sortPlanByDate,
  makeCardId,
};

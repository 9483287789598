import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpCode } from 'core/constants/app-constants';
import {
  getActivePlan,
  updateActivePlan,
} from 'core/services/idp/plan-service';
import { preProcessPlanForUI } from 'core/services/ui/plan-ui-data-service';
import { dispatchThunkError, pickEmplid } from './utils';

const initialState: PlanActiveState = {
  loading: false,
  saving: false,
  noActivePlanFound: false,
  data: null,
};

export const planActiveSlice = createSlice({
  name: 'planActive',
  initialState,
  reducers: {
    setPlanActiveLoading: (state, action: ActionOf<boolean>) => {
      state.loading = action.payload;
    },
    setPlanActiveSaving: (state, action: ActionOf<boolean>) => {
      state.saving = action.payload;
    },
    setPlanActiveNotFound: (state, action: ActionOf<boolean>) => {
      state.noActivePlanFound = action.payload;
    },
    setActivePlan: (
      state,
      { payload: rawPlan }: ActionOf<API.PlanData.Plan | null>,
    ) => {
      const planData = preProcessPlanForUI({ rawPlan })!;
      state.data = planData;
    },
  },
});

export const getActivePlanAsync = createAsyncThunk(
  'planSlice/getActivePlanAsync',
  async (
    {
      searchEmplId,
    }:
      | undefined
      | {
          searchEmplId?: string;
        } = {},
    { dispatch, getState, signal },
  ) => {
    dispatch(setPlanActiveLoading(true));
    dispatch(setPlanActiveNotFound(false));
    dispatch(setActivePlan(null));
    try {
      const emplid = searchEmplId || pickEmplid(getState());
      const plan = await getActivePlan(emplid, signal);
      dispatch(setActivePlan(plan));
    } catch (error) {
      const res = error as HTTPError;

      if (res.response?.status === HttpCode.NOT_FOUND) {
        dispatch(setPlanActiveNotFound(true));
      } else {
        dispatchThunkError(dispatch, error, getActivePlanAsync);
      }
    } finally {
      dispatch(setPlanActiveLoading(false));
    }
  },
);

export const updateActivePlanAsync = createAsyncThunk(
  'planSlice/updateActivePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    dispatch(setPlanActiveSaving(true));
    try {
      const emplid = pickEmplid(getState());
      const plan = await updateActivePlan(emplid, planId);

      dispatch(setActivePlan(plan));
      dispatch(setPlanActiveNotFound(false));

      return { saved: true };
    } catch (error) {
      dispatchThunkError(dispatch, error, updateActivePlanAsync);
      return { error, saved: false };
    } finally {
      dispatch(setPlanActiveSaving(false));
    }
  },
);

export const {
  setPlanActiveNotFound,
  setPlanActiveLoading,
  setPlanActiveSaving,
  setActivePlan,
} = planActiveSlice.actions;

export default planActiveSlice.reducer;

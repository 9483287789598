import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'core/auth';
import { BaseButton, MenuItem, MenuList } from 'core/components';
import { RoleName } from 'core/constants/app-constants';
import { appRouterUrl } from 'core/constants/router-url';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentProfile,
  useCurrentRoleSelector,
  useUserRolesSelector,
} from 'core/store';
import { setSelectedRoute, setShowNavbar } from 'core/store/slices';
import {
  requestIDPAuthTokenRoleAsync,
  setCurrentRole,
  setViewAsRole,
} from 'core/store/slices/userProfileSlice';

type MenuListItem = {
  text: string;
  url: string;
  roleSwitch?: UserProfileRole;
};

const profileMenuItem: MenuListItem = {
  text: 'Profile',
  url: appRouterUrl.STUDENT_PROFILE,
};

const adminMenuItem: MenuListItem = {
  text: 'Sign in as Admin',
  url: appRouterUrl.ADMIN_DASHBOARD,
  roleSwitch: RoleName.ADMIN,
};

const platformAdminMenuItem: MenuListItem = {
  text: 'Sign in as Platform admin',
  url: appRouterUrl.ADMIN_DASHBOARD,
  roleSwitch: RoleName.PLATFORM,
};

const advisorsMenuItem: MenuListItem = {
  text: 'Sign in as Advisor',
  url: appRouterUrl.ADMIN_DASHBOARD,
  roleSwitch: RoleName.ADVISOR,
};

const coachMenuItem: MenuListItem = {
  text: 'Sign in as Coach',
  url: appRouterUrl.ADMIN_DASHBOARD,
  roleSwitch: RoleName.COACH,
};

const studentMenuItem: MenuListItem = {
  text: 'Sign in as Student',
  url: appRouterUrl.STUDENT_DASHBOARD,
  roleSwitch: RoleName.STUDENT,
};

const signOutMenuItem: MenuListItem = {
  text: 'Sign Out',
  url: appRouterUrl.LOG_OUT,
};

/**
 * Build the menu up based on the user roles.
 */
function buildMenuList(
  currentRole: UserProfileRole | null,
  userRoles?: string[],
) {
  if (!userRoles || !currentRole) return [];

  const isMyOtherRole = (otherRole: UserProfileRole) =>
    currentRole !== otherRole && userRoles.includes(otherRole);
  //===============================================================
  // Build up the menu based on users roles which can be one or more.
  //===============================================================
  const menu: MenuListItem[] = [];

  if (currentRole === RoleName.STUDENT) {
    menu.push(profileMenuItem);
  }

  if (isMyOtherRole(RoleName.PLATFORM)) {
    menu.push(platformAdminMenuItem);
  }

  if (isMyOtherRole(RoleName.ADMIN)) {
    menu.push(adminMenuItem);
  }

  if (isMyOtherRole(RoleName.ADVISOR)) {
    menu.push(advisorsMenuItem);
  }

  if (isMyOtherRole(RoleName.COACH)) {
    menu.push(coachMenuItem);
  }

  if (isMyOtherRole(RoleName.STUDENT)) {
    menu.push(studentMenuItem);
  }

  menu.push(signOutMenuItem);

  return menu;
}

type Props = {
  disabled?: boolean;
  triggerIconUserName?: boolean;
};

const ProfileMenu: FC<Props> = ({ triggerIconUserName, disabled }) => {
  const dispatch = useAppDispatch();
  const isViewAsActive = useAppSelector(
    (state) => state.userProfile?.viewAs?.active,
  );
  const currentRole = useCurrentRoleSelector();
  // roles
  const {
    state: { userRoles, isAuthenticated },
  } = useAuthContext();
  const currentRoles = useUserRolesSelector(userRoles?.roles || []);
  // profile / full name
  const { profile } = useCurrentProfile();
  const { preferredName, firstName, lastName } = profile!;
  const userFullName = preferredName || `${firstName} ${lastName}`;
  const firstLetterName = (preferredName || firstName).substring(0, 1);

  let menu = buildMenuList(currentRole, currentRoles);

  return (
    <MenuList
      triggerComponent={(props) => (
        <BaseButton
          className={`place-self-center ${
            disabled ? 'text-gray-500' : 'text-gray-600 hover:text-gray-700'
          }`}
          disabled={disabled}
          {...props}
        >
          {triggerIconUserName ? (
            <span className="bg-gray-7 flex aspect-square w-8 items-center justify-center rounded-full text-white">
              {firstLetterName}
            </span>
          ) : (
            <i className={`fa-solid fa-circle-user h-6 w-6`} />
          )}
        </BaseButton>
      )}
      direction="right"
      disabled={!isAuthenticated || disabled}
    >
      <>
        <MenuItem customTabIndex>
          <div className="border-b-1 mb-2 border-b-gray-400 pb-3">
            <strong className="text-base font-bold text-gray-700">
              {userFullName}
            </strong>
            <div className="text-sm text-gray-500">{currentRole}</div>
          </div>
        </MenuItem>
        {menu.map((item, index) => (
          <MenuItem key={index} className="p-block-1" customTabIndex>
            <Link
              to={item.url}
              onClick={() => {
                const { url: selectedUrl, roleSwitch } = item;
                if (roleSwitch) {
                  dispatch(requestIDPAuthTokenRoleAsync(roleSwitch));
                  dispatch(setCurrentRole(roleSwitch));
                  if (isViewAsActive) {
                    dispatch(setViewAsRole(roleSwitch));
                  }
                }
                dispatch(setSelectedRoute(selectedUrl));
                dispatch(setShowNavbar(true));
              }}
            >
              {item.text}
            </Link>
          </MenuItem>
        ))}
      </>
    </MenuList>
  );
};

export { ProfileMenu, buildMenuList };
